import React,{useState} from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import './RigisterUser.css'

const RigisterUser = () => {
    const [inputData, setInputData] = useState({
        name:'',
        email:'',
        password:''
    });
    const data=(e)=>{
        const {name,value}=e.target;
        setInputData({...inputData,[name]:value})

    }
    const submitData=(e)=>{
        e.preventDefault();
        const{name,email,password}=inputData;
        if(!name||!email||!password){
            alert('Please Enter Details');
        }
        console.log(name,email,password);
        setInputData({name:'',email:'',password:''});
    }
    
  return (
    <>
    <Navbar/>
        <div className="content-spacing content-spacing-tb user-rigiter">
            <input type="text" placeholder='Enter Your Name' required name='name' value={inputData.name} onChange={data}/>
            <input type="email" placeholder='Enter Your Email' required name='email' value={inputData.email} onChange={data} />
            <input type="password" placeholder='Enter Your Password' required  name='password' value={inputData.password} onChange={data}/>
            <button type='submit' onClick={submitData}>Submit</button>
        </div>
        <Footer/>
    </>
  )
}

export default RigisterUser