import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {configureStore} from '@reduxjs/toolkit';
import {Provider} from "react-redux";
import productReducer, { productsFectch } from './Componenets/redux/slice';

const store=configureStore({
      reducer:{
            products:productReducer,
      }
});
store.dispatch(productsFectch());

export default store;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
