import React from 'react'
import './FooterStyle.css'

const Footer = () => {
  return (
    <>
        <div className="footer">
            <p className='content-spacing'>@ CopyRights 2022. All Rights are Researved.</p>
        </div>
    </>
  )
}

export default Footer