import React from "react";
import "./ShopStyle.css";
import ProductsData from "../ApiData/ProductsData";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";

const ShopData = () => {
  return (
    <>
      <Navbar />
      <div className="content-spacing content-spacing-tb shop-content">
        <div className="shop-inner-content">
          <h1>Price Filter</h1>
        </div>
        <div className="shop-inner-content shop-products-data">
          {ProductsData.map((product, index) => {
            return (
              <>
                <div className="shop-produts-content" key={index}>
                  <img src={product.img} alt={product.name} />
                  <h4>{product.name}</h4>
                  <div className="price-cart">
                    <p>${product.price}</p>
                    <Link to="#" className="add-cart-btn">
                      Add to Cart
                    </Link>
                  </div>
                </div>
                
              </>
            );
          })}
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default ShopData;
