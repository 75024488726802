import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SliderData from "../ApiData/SliderData";
import { Link } from "react-router-dom";
import "./HeroStyle.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export default () => {
  return (
    <Swiper
      // install Swiper modules
      modules={[Navigation, Pagination, Scrollbar]}
      //   spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      //   scrollbar={{ draggable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log("slide change")}
    >
      <div className="slider">
        {SliderData.map((slider, index) => {
          return (
            <>
              <SwiperSlide>
                <div
                  className="slider-data "
                  style={{
                    backgroundImage: `url('images/slider-1.jpg')`,
                    height: "600px",
                    backgroundPosition: "center-center",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="slider-content content-spacing">
                    <h1>{slider.title}</h1>
                    <p>{slider.desc}</p>
                    <div className="slider-btn">
                      <Link to="#">Shop Now</Link>
                      <Link to="#">Contact US</Link>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </>
          );
        })}
      </div>
    </Swiper>
  );
};
