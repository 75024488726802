import React from 'react'
import ShopData from '../Componenets/ShopData/ShopData'

const Shop = () => {
  return (
    <>
      <ShopData/>
    </>
  )
}

export default Shop