import './App.css';
import {BrowserRouter,Routes,Route} from 'react-router-dom';
import Home from './Pages/Home';
import Shop from './Pages/Shop';
import Register from './Pages/Register';
import LoginUser from './Componenets/LoginUser/LoginUser';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home/>}></Route>
          <Route path='/shop' element={<Shop/>}></Route>
          <Route path='/rigister' element={<Register/>}></Route>
          <Route path='/login' element={<LoginUser/>}></Route>
          <Route path='/shop' element={<Shop/>}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
