const ProductsData = [
  {
    id: "1",
    name: "iPhone 12 Pro Moment Case – Blue",
    img: "Images/Iphone-cover-1.jpg",
    price:20
  },
  {
    id: "2",
    name: "Full Aquarelle iPhone XR",
    img: "Images/Iphone-cover-2.jpg",
    price:30
  },
  {
    id: "3",
    name: "iPhone 12 Pro Moment Case – Olive",
    img: "Images/Iphone-cover-3.jpg",
    price:10
  },
  {
    id: "4",
    name: "Leather Case iPhone 12 Deep Violet",
    img: "Images/Iphone-cover-4.jpg",
    price:20
  },
  {
    id: "5",
    name: "iPhone 12 Pro Moment Case – Olive",
    img: "Images/Iphone-cover-5.jpg",
    price:30
  },
  {
    id: "6",
    name: "iPhone 13 Case Max –  Black",
    img: "Images/Iphone-cover-6.jpg",
    price:50
  }

];

export default ProductsData;
