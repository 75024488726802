import {createSlice,createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';


const initialState={

    items:[],
    status:null
}
export const productsFectch=createAsyncThunk("products",async()=>{
    const res=await axios.get('http://localhost:8400/product')
    return res.data;
})
const productSlice=createSlice({
    name:"products",
    initialState,
    reducers:{},
    extraReducers:{
        [productsFectch.pending]:(state,action)=>{
            state.status='pending'
        },
        [productsFectch.fulfilled]:(state,action)=>{
            state.status='success'
            state.items=action.payload
        },[productsFectch.rejected]:(state,action)=>{
            state.status='rejected'
        },
    }

});

export default productSlice.reducer;