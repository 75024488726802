const SliderData = [
  {
    img: "images/slider-1.jpg",
    title: "Charge Your Phone Safely!",
    desc: "A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart.",
  },
  {
    img: "images/slider-2.jpg",
    title: "For Everything and Everyone",
    desc: "Even if your less into design and more into content strategy you may find some redeeming value with, wait for it, dummy copy.",
  }
];

export default SliderData;