import React from 'react'
import Footer from '../Componenets/Footer/Footer'
import HeroSlider from '../Componenets/HeroSlider/HeroSlider'
import Navbar from '../Componenets/Navbar/Navbar'
import NewArrival from '../Componenets/NewArrival/NewArrival'

const Home = () => {
  return (
    <>
      <Navbar/>
      <HeroSlider/>
      <NewArrival/>
      <Footer/>
    </>
  )
}

export default Home