import React from 'react'
import RigisterUser from '../Componenets/RigisterUser/RigisterUser'

const Register = () => {
  return (
    <div>
        <RigisterUser/>
    </div>
  )
}

export default Register