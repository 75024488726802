import React from "react";
import "./ArrivalStyle.css";
import ProductsData from "../ApiData/ProductsData";

const NewArrival = () => {
  return (
    <>
      <div className="new-products content-spacing content-spacing-tb">
        {ProductsData.map((product, index) => {
          return (
            <>
              <div className="produts-content" key={index}>
                <img src={product.img} alt={product.name} />
                <h4>{product.name}</h4>
                <p>${product.price}</p>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default NewArrival;
