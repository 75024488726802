import React from "react";
import "./NavbarStyle.css";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillTwitterSquare,
  AiOutlineSearch,
  AiOutlineShopping
} from "react-icons/ai";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <>
      <div className="top-bar">
        <div className="top-bar-navigation content-spacing">
          <div className="disc-bar">
            <p>FREE SHIPPING FOR ALL ORDERS OF $150</p>
          </div>
          <div className="top-bar-icons">
            <AiFillFacebook />
            <AiFillInstagram />
            <AiFillTwitterSquare />
          </div>
          <div className="user-top-menu">
            <ul>
              
              <Link to="/rigister"><li>Register</li></Link>
              <Link to="/login"><li>Login</li></Link>
              
            </ul>
          </div>
        </div>
      </div>

      {/* =============== Navabr ============== */}

      <div className="main-nav-bar">
        <div className="navbar content-spacing">
          <div className="logo">
            <Link to="#">
              <img src={"images/logo.svg"} alt="logo" />
            </Link>
          </div>
          <div className="navigation-search-bar">
            <div className="nav-serach">
              <input type="text" placeholder="Search for Products" />
            </div>
            <div className="search-btn">
              <Link to="#">
                <AiOutlineSearch />
              </Link>
            </div>
          </div>

          <div className="main-menu">
            <ul>
              <Link to="/"><li>Home</li></Link>
              <Link to="/shop"><li>Shop</li></Link>
              <Link to="/contact"><li>Contact</li></Link>
              
            </ul>
          </div>
          <div className="menu-cart">
          <AiOutlineShopping/>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
